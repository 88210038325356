.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: rgba(38, 52, 101, 0.1) !important;
}
.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: rgba(38, 52, 101, 0.25) !important;
  color: #263465;
}
.DayPicker-Day--start.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #263465;
}
.DayPicker-Day--end.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #263465;
}
.DayPicker-Months {
  flex-wrap: nowrap;
}
.DayPicker-Caption {
  text-align: center;
  margin-top: 0;
}
.DayPicker-NavButton--prev {
  left: 1.5em;
}
.DayPicker-Body {
  display: flex;
  flex-direction: column;
  margin: 8px;
}
.DayPicker-Week {
  display: flex;
  flex-direction: row;
}
.DayPicker-Day {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border-radius: 20px !important;
  margin: 2px;
}
.DayPicker-Day--today {
  color: #161F3B;
}
.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #263465 !important;
  color: white;
}
.DayPicker-WeekdaysRow {
  display: flex;
  flex-direction: row;
  margin: 1em 8px;
}
.DayPicker-Weekday {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
}

.DayPicker-Month {
  margin: 0;
  margin-top: 1em;
}

@media screen and (min-width: 768px) {
  .DayPicker-Month {
    margin: 0 1em;
  }

  .DayPicker-Weekday {
    width: 30px;
  }

  .DayPicker-Day {
    width: 24px;
    height: 24px;
    border-radius: 24px !important;
  }

  .DayPicker-Body {
    margin: 0;
  }

  .DayPicker-Weekdays {
    margin: 1em 0 0 0;
  }

  .DayPicker-Caption {
    margin-top: 16px;
  }
}