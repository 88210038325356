@font-face {
  font-family: 'nunitobold';
  src: url('font/nunito-bold-webfont.woff2') format('woff2'),
       url('font/nunito-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'nunitoregular';
  src: url('font/nunito-regular-webfont.woff2') format('woff2'),
       url('font/nunito-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'nunitoregular';
  line-height: 1.25;
}

html, body, #root {
  height: 100%;
  scroll-behavior: smooth;
}

input,
textarea,
select,
button {
  font-family: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* React toggle */
.react-toggle--checked .react-toggle-thumb {
  left: 18px !important;
}

.react-toggle--checked .react-toggle-track {
  background: #008058 !important;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background: #ebebeb !important;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background: #008058 !important;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  box-shadow: none;
}

.MuiDialogActions-root {
  border-top: 1px solid #E0E0E0;
}

* {
  outline: none;
}