@font-face {
  font-family: 'azul';
  src: url('azul.eot?gpcvpr');
  src: url('azul.eot?gpcvpr#iefix') format('embedded-opentype'),
    url('azul.woff2?gpcvpr') format('woff2'),
    url('azul.ttf?gpcvpr') format('truetype'),
    url('azul.woff?gpcvpr') format('woff'),
    url('azul.svg?gpcvpr#azul') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'azul' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-line-search:before {
  content: '\e95e';
}
.icon-menu:before {
  content: '\e95d';
}
.icon-eye-open:before {
  content: '\e922';
}
.icon-eye-closed:before {
  content: '\e923';
}
.icon-error-outline:before {
  content: '\e001';
}
.icon-user-assistance:before {
  content: '\e95c';
}
.icon-master:before {
  content: '\e958';
}
.icon-visa:before {
  content: '\e959';
}
.icon-master-full:before {
  content: '\e95a';
}
.icon-visa-full:before {
  content: '\e95b';
}
.icon-ticket-outline:before {
  content: '\e927';
}
.icon-coin:before {
  content: '\e928';
}
.icon-tier-diamante:before {
  content: '\e94b';
}
.icon-tier-safira:before {
  content: '\e952';
}
.icon-tier-topazio:before {
  content: '\e955';
}
.icon-tier-tudoazul:before {
  content: '\e957';
}
.icon-tudoazul-plane:before {
  content: '\e924';
}
.icon-money-outline:before {
  content: '\e925';
}
.icon-contactless:before {
  content: '\e956';
}
.icon-camera:before {
  content: '\e921';
}
.icon-empty-notification:before {
  content: '\e91f';
}
.icon-circle-checked-filled:before {
  content: '\e91e';
}
.icon-switch:before {
  content: '\e91d';
}
.icon-up:before {
  content: '\e944';
}
.icon-down:before {
  content: '\e91b';
}
.icon-edit-pen:before {
  content: '\e938';
}
.icon-travelers:before {
  content: '\e91a';
}
.icon-promocode-stamp:before {
  content: '\e907';
}
.icon-arrow-back:before {
  content: '\e200';
}
.icon-insurance-money:before {
  content: '\e805';
}
.icon-payment-card:before {
  content: '\e806';
}
.icon-timer:before {
  content: '\e1b6';
}
.icon-azul-play:before {
  content: '\e919';
}
.icon-snack:before {
  content: '\e60c';
}
.icon-luggage-off:before {
  content: '\e926';
}
.icon-luggage:before {
  content: '\e917';
}
.icon-seat-default:before {
  content: '\e918';
}
.icon-seat-pay:before {
  content: '\e92b';
}
.icon-power:before {
  content: '\e916';
}
.icon-eye:before {
  content: '\e920';
}
.icon-send:before {
  content: '\e087';
}
.icon-home:before {
  content: '\e914';
}
.icon-Phone:before {
  content: '\e913';
}
.icon-radio-button-checked:before {
  content: '\e260';
}
.icon-radio-button-unchecked:before {
  content: '\e261';
}
.icon-Alert-Price-Filled:before {
  content: '\e910';
}
.icon-Alert-Price:before {
  content: '\e911';
}
.icon-smile-1:before {
  content: '\e90e';
  color: #606060;
}
.icon-smile-2:before {
  content: '\e90f';
  color: #606060;
}
.icon-before-delete:before {
  content: '\e080';
}
.icon-add:before {
  content: '\e92e';
}
.icon-alerts:before {
  content: '\e94c';
}
.icon-back:before {
  content: '\e91c';
}
.icon-calendar:before {
  content: '\e929';
}
.icon-caret-down:before {
  content: '\e942';
}
.icon-caret-up:before {
  content: '\e943';
}
.icon-chat:before {
  content: '\e94d';
}
.icon-check:before {
  content: '\e948';
}
.icon-check-circle:before {
  content: '\e94e';
}
.icon-checkbox-checked:before {
  content: '\e947';
}
.icon-checkbox-unchecked:before {
  content: '\e949';
}
.icon-clock:before {
  content: '\e937';
}
.icon-close:before {
  content: '\e945';
}
.icon-delete-forever:before {
  content: '\e92f';
}
.icon-disclosure-indicator:before {
  content: '\e92d';
}
.icon-edit:before {
  content: '\e930';
}
.icon-flight-update:before {
  content: '\e900';
}
.icon-history:before {
  content: '\e901';
}
.icon-info:before {
  content: '\e946';
}
.icon-info-text:before {
  content: '\e915';
}
.icon-key:before {
  content: '\e902';
}
.icon-map:before {
  content: '\e94f';
}
.icon-map-outline:before {
  content: '\e93c';
}
.icon-more-vertical:before {
  content: '\e950';
}
.icon-more:before {
  content: '\e912';
}
.icon-notification:before {
  content: '\e93a';
}
.icon-notification-filled:before {
  content: '\e939';
}
.icon-pax-adults:before {
  content: '\e90b';
}
.icon-pax-children:before {
  content: '\e90c';
}
.icon-pax-infant:before {
  content: '\e90d';
}
.icon-payments:before {
  content: '\e903';
}
.icon-place:before {
  content: '\e933';
}
.icon-place-circle:before {
  content: '\e931';
}
.icon-place-outline:before {
  content: '\e932';
}
.icon-plane:before {
  content: '\e951';
}
.icon-plane-filled:before {
  content: '\e93d';
}
.icon-plane-horizontal:before {
  content: '\e94a';
}
.icon-preferences:before {
  content: '\e904';
}
.icon-refresh:before {
  content: '\e934';
}
.icon-search:before {
  content: '\e936';
}
.icon-seat:before {
  content: '\e935';
}
.icon-seat-unavailable-mark:before {
  content: '\e941';
}
.icon-share:before {
  content: '\e92a';
}
.icon-star:before {
  content: '\e905';
}
.icon-star-filled:before {
  content: '\e906';
}
.icon-ticket:before {
  content: '\e93f';
}
.icon-ticket-filled:before {
  content: '\e93e';
}
.icon-track:before {
  content: '\e92c';
}
.icon-trip:before {
  content: '\e953';
}
.icon-trip-filled:before {
  content: '\e93b';
}
.icon-user:before {
  content: '\e954';
}
.icon-user-filled:before {
  content: '\e940';
}
.icon-userdata:before {
  content: '\e908';
}
.icon-warning:before {
  content: '\e909';
}
.icon-world:before {
  content: '\e90a';
}
