#materialUploadSlider .slick-dots {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0;
}

#materialUploadSlider .slick-dots li {
  width: 10px;
  height: 10px;
  background-color: #E0E0E0;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  margin: 4px;
}

#materialUploadSlider .slick-dots li.slick-active {
  background-color: #1077B9;
}

#materialUploadSlider .slick-dots li button {
  width: 10px;
  height: 10px;
  text-indent: -9999px;
  border-radius: 10px;
  overflow: hidden;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
}

#materialUploadSlider .slick-list {
  border-radius: 8px;
  border: 1px solid #E0E0E0;
}