#materialList {
  min-height: 140px;
}

#materialList .slick-slide {
  display: flex;
  width: 100% !important;
}

#materialList .slick-slide > div {
  width: 100%;
}

#materialList .slick-track {
  display: inline-flex;
  top: -16px;
}